@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
}
