table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

@media print {
  @page {
    size: A6 landscape;
  }
}
