@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

@media print {
  .pagebreak {
    display: block;
    page-break-before: always;
  }
}

@page {
  size: A4 landscape;
}
